import { X } from 'lucide-react'
import React, { useState } from 'react'
import { Button } from 'src/shared/components/ui'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'src/shared/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/shared/components/ui/popover'

import { Column, Row } from '../Semantic/all'
import { Badge } from './badge'
import { cn } from './utils'

export type SelectedItem = { value: string; id: number }

export function InputAutocomplete({
  selectedItems,
  setSelectedItems,
  options,
  setSearchValue,
  searchValue,
}: {
  selectedItems: SelectedItem[]
  setSelectedItems: (items: SelectedItem[]) => void
  options: { value: string; id: number }[]
  setSearchValue: (value: string) => void
  searchValue: string
}) {
  const [open, setOpen] = useState(false)

  const handleSelect = (currentValue: string) => {
    setSelectedItems([
      ...selectedItems,
      {
        value: currentValue,
        id: options.find((o) => o.value === currentValue)?.id || 0,
      },
    ])

    setSearchValue('')
  }

  const handleRemove = (item: SelectedItem) => {
    setSelectedItems(selectedItems.filter((i) => i.value !== item.value))
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchValue) {
      handleSelect(searchValue)
    }
  }

  return (
    <Column className="gap-2">
      <Row className="gap-2 flex-wrap">
        {selectedItems.map((item) => (
          <Badge
            key={item.value}
            variant="outline"
            className={cn(
              'cursor-pointer',
              item?.id ? '' : 'bg-orange-400 text-white'
            )}
          >
            {item.value}
            <X
              className="ml-1 h-3 w-3 inline-block"
              onClick={() => handleRemove(item)}
            />
          </Badge>
        ))}
      </Row>

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="justify-between w-[200px]"
          >
            Select or add option...
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput
              placeholder="Search option..."
              className="h-9"
              value={searchValue}
              onValueChange={setSearchValue}
              onKeyDown={handleKeyDown}
            />

            <CommandList>
              <CommandEmpty className="py-2 px-3">
                <p className="text-sm text-gray-500">
                  {searchValue
                    ? `Click enter to add "${searchValue}"`
                    : 'Type to search'}
                </p>
              </CommandEmpty>

              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={handleSelect}
                  >
                    {option.value}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </Column>
  )
}
