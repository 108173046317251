import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useAuth } from 'src/shared/hooks/authProvider'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { useOrgIdApi } from './apiOrgId'
import { queryKeys } from './queryKeys'

export type InsertUser = Database['public']['Tables']['user']['Insert']
export type InsertOrgUser = Database['public']['Tables']['org_user']['Insert']
export type OrgUser_ = Database['public']['Tables']['org_user']['Row']
export type User_ = Database['public']['Tables']['user']['Row']

export const useOrgUsersApi = () => {
  const { session } = useAuth()
  const { orgId: org_id } = useOrgIdApi()

  const queryClient = useQueryClient()
  const supabase = useSupabaseClient()
  const { toast } = useToast()

  const { data: orgUser } = useQuery({
    queryKey: [queryKeys.org_user, session?.user?.id],
    queryFn: async () =>
      await supabase
        .from('org_user')
        .select(`*, user!org_user_user_id_fkey(*), role(*), organization(*)`)
        .eq('user_id', session?.user?.id!)
        .eq('organization_id', org_id!)
        .eq('removed', false)
        .single(),
    enabled: !!session?.user?.id && !!org_id,
  })

  const { data: orgUsers } = useQuery({
    queryKey: [queryKeys.org_users],
    queryFn: async () =>
      await supabase
        .from('org_user')
        .select(`*, user!org_user_user_id_fkey(*), role(*), organization(*)`)
        .filter('organization_id', 'eq', org_id),
    enabled: !!org_id,
  })

  const { data: userAllOrgs } = useQuery({
    queryKey: ['usersAllOrgs'],
    queryFn: async () =>
      await supabase
        .from('org_user')
        .select(`*, user!org_user_user_id_fkey(*), role(*), organization(*)`)
        .eq('user_id', session?.user?.id!),
    enabled: !!session?.user?.id,
  })

  const updateUser = useMutation({
    mutationFn: async (insertUser: InsertUser) =>
      await supabase.from('user').update(insertUser).eq('id', insertUser.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })

      toast({
        variant: 'success',
        title: 'Updated user',
      })
    },
  })

  const updateOrgUser = useMutation({
    mutationFn: async (insertOrgUser: InsertOrgUser) =>
      await supabase
        .from('org_user')
        .update(insertOrgUser)
        .eq('id', insertOrgUser.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })
    },
  })

  const createOrgUser = useMutation({
    mutationFn: async (insertOrgUser: InsertOrgUser) =>
      await supabase.from('org_user').insert(insertOrgUser),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })
      toast({
        variant: 'success',
        title: 'Created org user',
      })
    },
  })

  const deleteOrgUser = useMutation({
    mutationFn: async ({ id }: { id: number }) =>
      await supabase.from('org_user').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })
      toast({
        variant: 'success',
        title: 'Removed user from organization',
      })
    },
  })

  return {
    orgUser,
    orgUsers,
    updateUser,
    updateOrgUser,
    createOrgUser,
    deleteOrgUser,
    userAllOrgs,
  }
}
