import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { queryKeys } from './queryKeys'

export type InsertVendor = Database['public']['Tables']['vendor']['Insert']
export type UpdateVendor = Database['public']['Tables']['vendor']['Update']
export type Vendor_ = Database['public']['Tables']['vendor']['Row']

export const useVendorsApi = ({
  orgId,
  search,
}: {
  orgId?: string
  search?: string
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { data: vendorsSearch } = useQuery({
    queryKey: [queryKeys.vendorsSearch],
    queryFn: async () =>
      await supabase
        .from('vendor')
        .select('*, tool(id)')
        .or(`organization_id.eq.${orgId}, organization_id.is.null`)
        .order('root_domain', { ascending: true })
        .ilike('root_domain', `%${search}%`),
    enabled: !!orgId && !!search?.length && search?.length > 2,
  })

  const updateVendor = useMutation({
    mutationFn: async (updateVendor: UpdateVendor) =>
      await supabase
        .from('vendor')
        .update(updateVendor)
        .eq('id', updateVendor.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.vendors] })

      toast({
        variant: 'success',
        title: 'Updated vendor',
      })
    },
  })

  const deleteVendor = useMutation({
    mutationFn: async (id: string) =>
      await supabase.from('vendor').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.vendors] })
    },
  })

  return {
    vendorsSearch,
    updateVendor,
    deleteVendor,
  }
}
