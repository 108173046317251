import { CaretDownIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { Chrome, RocketIcon, SendHorizonal, Users } from 'lucide-react'
import { useState } from 'react'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useOrgUsersApi } from 'src/api/hooks/apiOrgUsers'
import { useOrgsApi } from 'src/api/hooks/apiOrgs'
import { queryKeys } from 'src/api/hooks/queryKeys'
import { useInviteAdmins } from 'src/api/server/useInviteAdmins'
import { useInviteExtUsers } from 'src/api/server/useInviteExtUsers'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import { Alert, AlertDescription } from 'src/shared/components/ui/alert'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from 'src/shared/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'
import { Textarea } from 'src/shared/components/ui/textarea'
import { getSubscriptionTeamLimit } from 'src/shared/consts'

import { TeamTable } from './TeamTable'

export const TeamPage = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isInviteAdminModalOpen, setIsInviteAdminModalOpen] = useState(false)
  const [isInviteExtensionModalOpen, setIsInviteExtensionModalOpen] =
    useState(false)
  const [adminEmails, setAdminEmails] = useState('')
  const [extensionEmails, setExtensionEmails] = useState('')
  const queryClient = useQueryClient()

  const inviteAdmins = useInviteAdmins()
  const inviteExtensionUsers = useInviteExtUsers()

  const { orgId } = useOrgIdApi()
  const { orgUsers } = useOrgUsersApi()
  const { organization } = useOrgsApi({
    orgId,
  })

  const teamLimit = getSubscriptionTeamLimit(
    organization?.data?.stripe_product_id
  )

  const handleInviteAdmins = async () =>
    await inviteAdmins
      .mutateAsync({ emails: adminEmails, organization_id: orgId || '' })
      .then(() => {
        setAdminEmails('')
        setIsInviteAdminModalOpen(false)
        queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })
      })

  const handleInviteExtension = async () =>
    await inviteExtensionUsers
      .mutateAsync({
        emails: extensionEmails,
        organization_id: orgId || '',
      })
      .then(() => {
        setExtensionEmails('')
        setIsInviteExtensionModalOpen(false)
        queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })
      })

  return (
    <Column className="gap-4 w-full h-full">
      <Row className="gap-2 items-center mb-1">
        <Users />
        <h1 className="text-2xl font-medium">Team</h1>

        <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
          <DropdownMenuTrigger asChild>
            <Button className="ml-auto">
              Actions
              <CaretDownIcon className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            <DropdownMenuItem
              onClick={() => {
                setIsInviteAdminModalOpen(true)
                setMenuOpen(false)
              }}
            >
              Invite admins
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                setIsInviteExtensionModalOpen(true)
                setMenuOpen(false)
              }}
            >
              Invite extension users
            </DropdownMenuItem>

            <DropdownMenuItem
              onClick={() => {
                window.open(
                  'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
                  '_blank'
                )
                setMenuOpen(false)
              }}
            >
              Download extension
              <Chrome className="ml-2 h-4 w-4 text-primary" />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Row>

      <Card>
        <TeamTable />
      </Card>

      <Dialog
        open={isInviteAdminModalOpen}
        onOpenChange={setIsInviteAdminModalOpen}
      >
        <DialogContent>
          <DialogHeader>
            <h1 className="text-xl font-semibold">Invite admins</h1>
          </DialogHeader>

          <p className="text-sm font-normal text-muted-foreground">
            Invite admins by entering one or more email addresses. Separate by
            commas.
          </p>

          <Textarea
            className="mt-4"
            placeholder="admin@example.com, admin2@example.com, admin3@example.com"
            rows={5}
            value={adminEmails}
            onChange={(e) => setAdminEmails(e.target.value)}
          />

          <Row className="justify-between">
            <Badge className="mt-4 rounded-2xl" variant="outline">
              {teamLimit -
                (orgUsers?.data?.length || 0) +
                1 -
                adminEmails.split(',').length}{' '}
              / {teamLimit} users
            </Badge>

            <Button
              className="ml-auto mt-4"
              onClick={handleInviteAdmins}
              isLoading={inviteAdmins.isLoading}
              disabled={
                (orgUsers?.data?.length || 0) +
                  adminEmails.split(',').length -
                  1 >
                teamLimit - 1
              }
            >
              Send invites
              <SendHorizonal className="ml-2 h-4 w-4" />
            </Button>
          </Row>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isInviteExtensionModalOpen}
        onOpenChange={setIsInviteExtensionModalOpen}
      >
        <DialogContent>
          <DialogHeader>
            <h1 className="text-xl font-semibold">Invite extension users</h1>
          </DialogHeader>

          <Alert variant="info" className="mt-4">
            <RocketIcon className="h-4 w-4" />
            <AlertDescription>
              In order to track activity, your teammates need to activate their
              accounts and install our browser extension.
            </AlertDescription>
          </Alert>

          <Textarea
            className="mt-4"
            placeholder="extension@example.com, extension2@example.com, extension3@example.com"
            rows={5}
            value={extensionEmails}
            onChange={(e) => setExtensionEmails(e.target.value)}
          />

          <Row className="justify-between">
            <Badge className="mt-4 rounded-2xl" variant="outline">
              {teamLimit -
                (orgUsers?.data?.length || 0) +
                1 -
                extensionEmails.split(',').length}{' '}
              / {teamLimit} users
            </Badge>

            <Button
              className="mt-4 ml-auto"
              onClick={handleInviteExtension}
              isLoading={inviteExtensionUsers.isLoading}
              disabled={
                (orgUsers?.data?.length || 0) +
                  extensionEmails.split(',').length -
                  1 >
                teamLimit - 1
              }
            >
              Send invites
              <SendHorizonal className="ml-2 h-4 w-4" />
            </Button>
          </Row>
        </DialogContent>
      </Dialog>
    </Column>
  )
}
