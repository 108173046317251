import { useQueryClient } from '@tanstack/react-query'
import { Cctv, Layers, PlusCircle } from 'lucide-react'
import { useState } from 'react'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useOrgUsersApi } from 'src/api/hooks/apiOrgUsers'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { useVendorsApi } from 'src/api/hooks/apiVendors'
import { queryKeys } from 'src/api/hooks/queryKeys'
import { useAddVendors } from 'src/api/server/useAddVendors'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from 'src/shared/components/ui/dialog'
import {
  InputAutocomplete,
  SelectedItem,
} from 'src/shared/components/ui/input-autocomplete'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'
import { useDebounce } from 'use-debounce'

import { Receipts } from './Receipts'
import { StackTable } from './StackTable'
import { UntrackedTable } from './UntrackedTable'

export const StackPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([])
  const [searchValue, setSearchValue] = useState('')

  const queryClient = useQueryClient()
  const addVendors = useAddVendors()
  const { orgId } = useOrgIdApi()
  const [searchText] = useDebounce(searchValue, 250)
  const { orgUser } = useOrgUsersApi()

  const { vendorsSearch } = useVendorsApi({
    orgId: orgId || '',
    search: searchText,
  })

  const { tools, upsertTools } = useToolsApi({ organization_id: orgId || '' })
  const untrackedTools = tools?.tools?.filter(
    (tool) => tool.status === 'not_in_stack'
  )

  const options = searchValue
    ? vendorsSearch?.data?.map((vendor) => ({
        id: vendor.id,
        value: vendor.root_domain || '',
      })) || []
    : []

  return (
    <Column className="gap-4 w-full h-full">
      <Row className="gap-2 items-center mb-1">
        <Layers />
        <h1 className="text-2xl font-medium">Stack</h1>

        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogTrigger asChild>
            <Button className="ml-auto">
              <PlusCircle className="mr-2 h-4 w-4" />
              Add tools
            </Button>
          </DialogTrigger>

          <DialogContent className="max-w-lg min-h-[300px]">
            <Column className="gap-2">
              <p className="text-lg font-medium">Add tools to your stack</p>

              <p className="text-sm font-normal text-muted-foreground">
                If you can't find an app, add a{' '}
                <span className="text-orange-500">custom vendor</span>. They
                will be automatically generated and added to your tool stack.
                <br />
                <br />
                Or <span className="font-bold">invite extension users</span> to
                start to start identifying their tools.
              </p>
            </Column>

            <Row className="gap-4 mt-4 items-end">
              <InputAutocomplete
                options={options}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
              />

              <Button
                className="ml-auto"
                disabled={selectedItems.length === 0}
                onClick={async () => {
                  // The vendors that exist, we add as a tool
                  upsertTools.mutate(
                    selectedItems
                      .filter((item) => item.id !== 0)
                      .map((item) => ({
                        vendor_id: item.id,
                        organization_id: orgId || '',
                        department:
                          vendorsSearch?.data?.find(
                            (vendor) => vendor.id === item.id
                          )?.category || '',
                        owner_org_user_id: orgUser?.data?.id!,
                        status: 'in_stack',
                        is_tracking: true,
                      }))
                  )

                  // The vendors that does not exist, we create a new vendor and also add as a tool
                  addVendors
                    .mutateAsync({
                      owner_org_user_id: orgUser?.data?.id!,
                      vendors: selectedItems
                        .filter((item) => !item.id)
                        .map((item) => item.value),
                      organization_id: orgId,
                    })
                    .then(() => {
                      queryClient.invalidateQueries({
                        queryKey: [queryKeys.tools],
                      })
                    })
                  setIsModalOpen(false)
                  setSelectedItems([])
                }}
              >
                <PlusCircle className="mr-2 h-4 w-4" />
                Add {selectedItems.length || ''} tools
              </Button>
            </Row>
          </DialogContent>
        </Dialog>
      </Row>

      <Tabs defaultValue="tracked">
        <TabsList>
          <TabsTrigger value="tracked">
            <Cctv className="mr-2 h-4 w-4" />
            Tracked
          </TabsTrigger>

          <TabsTrigger value="untracked">
            Untracked (
            {untrackedTools?.filter((tool) => tool.status === 'not_in_stack')
              ?.length || 0}
            )
          </TabsTrigger>

          {/* <TabsTrigger value="receipts">Receipts</TabsTrigger> */}
        </TabsList>

        <TabsContent value="tracked">
          <Card>
            <StackTable />
          </Card>
        </TabsContent>

        <TabsContent value="untracked">
          <Card>
            <UntrackedTable />
          </Card>
        </TabsContent>

        <TabsContent value="receipts">
          <Card>
            <Receipts />
          </Card>
        </TabsContent>
      </Tabs>
    </Column>
  )
}
