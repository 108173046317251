import {
  Building2,
  FlaskConical, // Calendar,
  Layers,
  LayoutDashboard,
  PartyPopper,
  Users,
} from 'lucide-react'
import { paths } from 'src/routes/paths'

export const links = [
  {
    label: 'Dashboard',
    href: paths.dashboard,
    icon: (
      <LayoutDashboard className="text-neutral-700 h-5 w-5 flex-shrink-0" />
    ),
  },
  {
    label: 'Stack',
    href: paths.stack,
    icon: <Layers className="text-neutral-700 h-5 w-5 flex-shrink-0" />,
  },
  {
    label: 'Insights',
    href: paths.insights,
    icon: <FlaskConical className="text-neutral-700 h-5 w-5 flex-shrink-0" />,
  },
  {
    label: 'Team',
    href: paths.team,
    icon: <Users className="text-neutral-700 h-5 w-5 flex-shrink-0" />,
  },
  {
    label: 'Organization',
    href: paths.organization,
    icon: <Building2 className="text-neutral-700 h-5 w-5 flex-shrink-0" />,
  },
  {
    label: 'Request feature',
    href: paths.requestFeature,
    icon: <PartyPopper className="text-neutral-700 h-5 w-5 flex-shrink-0" />,
  },
]
