import { ExternalLink } from 'lucide-react'
import React, { useState } from 'react'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useOrgUsersApi } from 'src/api/hooks/apiOrgUsers'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Alert } from 'src/shared/components/ui/alert'
import { Button } from 'src/shared/components/ui/button'
import { Input } from 'src/shared/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'

export const UntrackedTable: React.FC = () => {
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const { updateTool } = useToolsApi({ organization_id: orgId || '' })
  const [filterStatus, setFilterStatus] = useState<string>('not_in_stack')
  const [filterText, setFilterText] = useState('')
  const { orgUser } = useOrgUsersApi()

  const filteredTools = tools?.tools?.filter(
    (tool) =>
      tool.status === filterStatus &&
      (filterText === '' ||
        tool.vendor.name?.toLowerCase().includes(filterText.toLowerCase()) ||
        tool.vendor.root_domain
          ?.toLowerCase()
          .includes(filterText.toLowerCase()))
  )

  return (
    <>
      <Row className="mb-4 gap-2 items-center justify-between">
        <Input
          placeholder="Filter tools..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          className="w-[300px]"
        />
        <Select onValueChange={setFilterStatus} defaultValue="not_in_stack">
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Filter by status" />
          </SelectTrigger>

          <SelectContent>
            <SelectItem value="not_in_stack">
              Not in stack (
              {
                tools?.tools?.filter((tool) => tool.status === 'not_in_stack')
                  ?.length
              }
              )
            </SelectItem>
            <SelectItem value="ignored">
              Ignored (
              {
                tools?.tools?.filter((tool) => tool.status === 'ignored')
                  ?.length
              }
              )
            </SelectItem>
          </SelectContent>
        </Select>
      </Row>

      <div className="overflow-auto max-h-[400px]">
        <Table>
          <TableBody>
            {!filteredTools?.length && (
              <TableRow>
                <TableCell className="h-24">
                  <Alert className="w-fit mx-auto my-4">
                    <span className="font-bold">Invite extension users</span> to
                    start identifying their tools
                  </Alert>
                </TableCell>
              </TableRow>
            )}

            {filteredTools?.map((tool) => {
              return (
                <TableRow key={tool.id} className="bg-gray-50/80">
                  <TableCell>
                    <Row className="flex items-center gap-3">
                      <SaasIcon
                        toolName={tool?.vendor.name}
                        src={tool?.vendor.logo_url || ''}
                        size="lg"
                      />
                      <Column>
                        <h2
                          className="text-sm font-medium cursor-pointer"
                          onClick={() =>
                            window.open(tool?.vendor.url ?? '', '_blank')
                          }
                        >
                          <Row>
                            {tool?.vendor.name}
                            <ExternalLink className="w-4 h-4 ml-2" />
                          </Row>
                        </h2>

                        <span className="text-xs font-normal">
                          {tool.vendor.root_domain}
                        </span>
                      </Column>
                    </Row>
                  </TableCell>

                  {tool.status === 'not_in_stack' && (
                    <TableCell className="text-right">
                      <>
                        <Button
                          className=""
                          variant="ghost"
                          onClick={() => {
                            updateTool.mutate({
                              id: tool.id!,
                              status: 'ignored',
                            })
                          }}
                        >
                          Ignore
                        </Button>

                        <Button
                          className=""
                          variant="outline"
                          onClick={async () => {
                            await updateTool.mutateAsync({
                              id: tool.id!,
                              vendor_id: tool?.vendor.id!,
                              organization_id: orgId || '',
                              department: tool?.vendor.category || '',
                              owner_org_user_id: orgUser?.data?.id,
                              is_tracking: true,
                              status: 'in_stack',
                            })
                          }}
                        >
                          Add to Stack
                        </Button>
                      </>
                    </TableCell>
                  )}

                  {tool.status === 'ignored' && (
                    <TableCell className="text-right">
                      <>
                        <Button
                          className=""
                          variant="outline"
                          onClick={async () => {
                            await updateTool.mutateAsync({
                              id: tool.id!,
                              department: tool?.vendor.category || '',
                              owner_org_user_id: orgUser?.data?.id,
                              is_tracking: true,
                              status: 'in_stack',
                            })
                          }}
                        >
                          Add to Stack
                        </Button>
                      </>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
