export const paths = {
  loginSignUp: '/login-signup',
  extension: '/extension',
  validateDomains: '/validate-domains',
  cookieConsent: '/cookieConsent',
  privacyPolicy: '/privacyPolicy',
  termsOfService: '/termsOfService',
  requestFeature: '/request-feature',

  dashboard: '/dashboard',
  stack: '/stack',
  renewals: '/renewals',
  insights: '/insights',
  team: '/team',
  userSettings: '/user-settings',
  organization: '/organization',
}
