import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { Alert } from 'src/shared/components/ui/alert'
import { Input } from 'src/shared/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/shared/components/ui/table'

import { useColumns } from './columns'

export function StackTable() {
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'spendMonthly',
      desc: true,
    },
  ])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})
  const columns = useColumns()

  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tools_ = React.useMemo(() => tools, [tools])

  const tableData = React.useMemo(
    () =>
      tools_?.tools
        ?.filter((tool) => tool.status === 'in_stack')
        .map((tool) => {
          return {
            app: {
              name: tool.vendor.name || '',
              id: tool.id || '',
              logo_url: tool.vendor.logo_url || '',
            },
            dept: tool.department || '',
            owner: tool.budget_owner?.user?.email || '',
            MAUs: tool.calculated?.userCount,
            spendMonthly: tool.calculated?.priceMonth,
            spendAnnual: tool.calculated?.priceAnnual,
            tracking: tool.is_tracking,
          }
        }) ?? [],
    [tools_]
  )

  const table = useReactTable({
    data: tableData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination: {
        pageIndex: 0,
        pageSize: 10000,
      },
    },
  })

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <Input
          placeholder="Filter applications..."
          value={(table.getColumn('app')?.getFilterValue() as string) ?? ''}
          onChange={(event) =>
            table.getColumn('app')?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
      </div>

      <div
        className="border rounded-lg"
        style={{
          height: '350px',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <Table>
          <TableHeader className="sticky top-0 z-10 bg-background">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24">
                  <Alert className="w-fit mx-auto my-4">
                    Click <span className="font-bold">Add tools</span> or use{' '}
                    <span className="font-bold">Untracked tools</span> to track
                    activities
                  </Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
      </div>
    </div>
  )
}
