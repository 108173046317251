import {
  Chrome,
  CreditCard,
  LayoutDashboard,
  MousePointerClick,
  RefreshCcw,
  Wallet,
} from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from 'src/shared/components/ui/alert'

import { Bars } from './Bars'
import MonthCalendar from './MonthCalendar'
import { StatCard } from './StatCard'

export const DashboardPage = () => {
  const navigate = useNavigate()

  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({
    organization_id: orgId || '',
  })

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-2 items-center mb-1 py-1">
        <LayoutDashboard />
        <h1 className="text-2xl font-medium">Dashboard</h1>
      </Row>

      <Row className="gap-4">
        <StatCard
          title="Annual spend"
          value={`$${Math.round(tools?.toolsStats.annualSpend || 0)}`}
          icon={<CreditCard className="w-6 h-6 text-muted-foreground" />}
          description="Current run-rate"
        />

        <StatCard
          title="Monthly spend"
          value={`$${Math.round(tools?.toolsStats.monthlySpend || 0)}`}
          icon={<CreditCard className="w-6 h-6 text-muted-foreground" />}
          description="Current run-rate"
        />

        <StatCard
          title="Active users"
          value={tools?.toolsStats.totalActiveUsers || 0}
          icon={<MousePointerClick className="w-6 h-6 text-muted-foreground" />}
          description="Last 30 days"
        />

        <StatCard
          title="Active subscriptions"
          value={tools?.toolsStats.totalActiveSubscriptions || 0}
          icon={<RefreshCcw className="w-6 h-6 text-muted-foreground" />}
          description="Last 30 days"
        />
      </Row>

      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-3">
          <MonthCalendar />
        </div>

        <div className="col-span-2 gap-4">
          <Column className="gap-4">
            {tools?.toolsStats.totalActiveUsers === 0 && (
              <Alert>
                <AlertTitle>Tip!</AlertTitle>
                <AlertDescription>
                  <p>
                    You don't seem to have any data yet. Download the extension
                    and wait about 10 minutes.
                  </p>

                  <Button
                    className="mt-3"
                    size="sm"
                    onClick={() => {
                      window.open(
                        'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
                        '_blank'
                      )
                    }}
                  >
                    <Chrome className="mr-2 w-4 h-4" />
                    Download Extension
                  </Button>
                </AlertDescription>
              </Alert>
            )}

            <Row className="gap-4">
              <StatCard
                title="Seat utilization"
                value={`$${
                  tools?.toolsStats.totalAnnualWasteSeatUtilization || 0
                }`}
                icon={<Wallet className="w-6 h-6 text-muted-foreground" />}
                description="Potential savings"
                color={
                  tools?.toolsStats?.totalAnnualWasteSeatUtilization! > 0
                    ? 'green'
                    : null
                }
                onClick={() => navigate(paths.insights)}
              />

              <StatCard
                title="Low user count"
                value={`$${tools?.toolsStats.totalAnnualWasteLowUsage || 0}`}
                icon={<Wallet className="w-6 h-6 text-muted-foreground" />}
                description="Potential savings"
                color={
                  tools?.toolsStats?.totalAnnualWasteLowUsage! > 0
                    ? 'green'
                    : null
                }
                onClick={() => navigate(paths.insights)}
              />
            </Row>

            <Bars />
          </Column>
        </div>
      </div>
    </Column>
  )
}
