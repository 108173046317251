import { FlaskConical } from 'lucide-react'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'

import { Activity } from './Activity'
import { CostReduction } from './CostReduction'

export const InsightsPage = () => {
  return (
    <Column className="gap-4 w-full">
      <Row className="gap-2 items-center mb-1 py-1">
        <FlaskConical />
        <h1 className="text-2xl font-medium">Insights</h1>
      </Row>

      <Tabs defaultValue="cost-reduction">
        <TabsList>
          <TabsTrigger value="cost-reduction">Cost reduction</TabsTrigger>
          {/* <TabsTrigger value="overview">Overview</TabsTrigger> */}
          <TabsTrigger value="activity">Activity</TabsTrigger>
        </TabsList>

        <TabsContent value="cost-reduction">
          <CostReduction />
        </TabsContent>

        <TabsContent value="overview">Trends, overview</TabsContent>

        <TabsContent value="activity">
          <Activity />
        </TabsContent>
      </Tabs>
    </Column>
  )
}
