import { createBrowserRouter } from 'react-router-dom'
import { DashboardPage } from 'src/pages/Dashboard'
import { ExtensionPage } from 'src/pages/Extension'
import { ExtensionLoginPage } from 'src/pages/ExtensionLogin'
import { CookieConsent } from 'src/pages/GDPR/CookieConsent'
import { PrivacyPolicy } from 'src/pages/GDPR/PrivacyPolicy'
import { TermsOfService } from 'src/pages/GDPR/TermsOfService'
import { InsightsPage } from 'src/pages/Insights'
import { LoginSignup } from 'src/pages/LoginSignup'
import { OrganizationPage } from 'src/pages/Organization'
import { RequestFeaturePage } from 'src/pages/RequestFeature'
import { StackPage } from 'src/pages/Stack'
import { TeamPage } from 'src/pages/Team'
import { ToolPage } from 'src/pages/Tool'
import { UserSettingsPage } from 'src/pages/UserSettings'
import { ValidateDomainsPage } from 'src/pages/ValidateDomains'
import { AppLayout } from 'src/shared/components/AppLayout'

import { ProtectedRoute } from './components/ProtectedRoute'
import { paths } from './paths'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout sidebar />,
    children: [
      {
        index: true,
        element: <ProtectedRoute component={DashboardPage} />,
      },
      {
        path: paths.dashboard,
        element: <ProtectedRoute component={DashboardPage} />,
      },
      {
        path: paths.stack,
        element: <ProtectedRoute component={StackPage} />,
      },
      {
        path: paths.stack + '/:tool_id',
        element: <ProtectedRoute component={ToolPage} />,
      },
      {
        path: paths.insights,
        element: <ProtectedRoute component={InsightsPage} />,
      },
      {
        path: paths.team,
        element: <ProtectedRoute component={TeamPage} />,
      },
      {
        path: paths.requestFeature,
        element: <ProtectedRoute component={RequestFeaturePage} />,
      },
      {
        path: paths.organization,
        element: <ProtectedRoute component={OrganizationPage} />,
      },
      {
        path: paths.userSettings,
        element: <ProtectedRoute component={UserSettingsPage} />,
      },
    ],
  },
  // --- Routes without sidebar ---
  {
    path: '/',
    element: <AppLayout />,
    children: [
      // --- Protected routes ---
      {
        path: paths.loginSignUp + '/:org_id?',
        element: <LoginSignup />,
      },
      {
        path: paths.validateDomains,
        element: <ValidateDomainsPage />,
      },
      // Open routes
      {
        path: paths.cookieConsent,
        element: <CookieConsent />,
      },
      {
        path: paths.privacyPolicy,
        element: <PrivacyPolicy />,
      },
      {
        path: paths.termsOfService,
        element: <TermsOfService />,
      },
      {
        path: paths.extension,
        element: <ExtensionPage />,
      },
      {
        path: `${paths.extension}/login`,
        element: <ExtensionLoginPage />,
      },
    ],
  },
])

// {
//   path: paths.candidates + '/:company_id/:profile_ids',
//   element: <SharedProfiles />,
// },
