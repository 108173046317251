import { zodResolver } from '@hookform/resolvers/zod'
import { LogIn, X } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSupabaseClient } from 'src/api/supabase'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card, Image } from 'src/shared/components/ui'
import { Alert, AlertDescription } from 'src/shared/components/ui/alert'
import { Button } from 'src/shared/components/ui/button'
import { Input } from 'src/shared/components/ui/input'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'
import { useToast } from 'src/shared/hooks/use-toast'
import { z } from 'zod'

const formSchema = z.object({
  email: z.string().email(),
  team_name: z.string().min(1),
  invite_code: z.string().min(1),
})

export const LoginSignup = () => {
  const [loading, setLoading] = useState(false)
  const supabaseClient = useSupabaseClient()
  const [success, setSuccess] = useState(false)
  const { toast } = useToast()

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      email: '',
      team_name: '',
      invite_code: '',
    },
    resolver: zodResolver(formSchema),
  })

  const onError = (error) => {
    const msg = JSON.stringify(error.message).slice(0, 250)
    const message = msg.includes('Signups not allowed for otp')
      ? 'You need to create a user'
      : msg

    toast({
      variant: 'destructive',
      // @ts-ignore
      title: (
        <Row className="flex items-center gap-1">
          <X className="h-4 w-4" />
          {message || 'Something went wrong'}
        </Row>
      ),
    })
  }

  const signUp = async () => {
    setLoading(true)

    const { data: existingUser } = await supabaseClient
      .from('user')
      .select()
      .eq('email', form.getValues('email').toLowerCase())
      .single()

    if (existingUser) {
      toast({
        title: 'Something went wrong',
        description: 'User already exists. Please login.',
        variant: 'destructive',
        duration: 5000,
      })
      setLoading(false)
      return
    }

    if (form.getValues('invite_code') !== 'earlybird2024') {
      toast({
        title: 'Something went wrong',
        description: 'Incorrect invite code',
        variant: 'destructive',
        duration: 5000,
      })
      form.setError('invite_code', {
        type: 'manual',
        message: 'Invalid invite code',
      })
      setLoading(false)
      return
    }

    const { error } = await supabaseClient.auth.signInWithOtp({
      email: form.getValues('email').toLowerCase(),
      options: {
        emailRedirectTo: process.env.REACT_APP_FE_SERVER_URL,
        data: {
          team_name: form.getValues('team_name'),
          role: 'superadmin',
          stripe_product_id: form.getValues('invite_code'),
        },
      },
    })

    if (error) {
      onError(error)
    } else {
      setSuccess(true)
    }
    setLoading(false)
  }

  const login = async () => {
    setLoading(true)

    const { data: existingUser } = await supabaseClient
      .from('user')
      .select()
      .eq('email', form.getValues('email').toLowerCase())
      .single()

    if (!existingUser) {
      toast({
        title: 'Something went wrong',
        description: 'User not found. Please sign up first.',
        variant: 'destructive',
        duration: 5000,
      })
      setLoading(false)
      return
    }

    const { error } = await supabaseClient.auth.signInWithOtp({
      email: form.getValues('email').toLowerCase(),
      options: {
        emailRedirectTo: process.env.REACT_APP_FE_SERVER_URL,
      },
    })

    if (error) {
      onError(error)
    } else {
      setSuccess(true)
    }
    setLoading(false)
  }

  if (success) {
    return (
      <div className="w-full h-[100vh] flex items-center justify-center">
        <Card>
          <Column className="gap-1">
            <h2 className="text-md font-normal">Success! ✅</h2>
            <p className="text-sm text-muted-foreground">
              To enter, click the link in your email.
            </p>
          </Column>
        </Card>
      </div>
    )
  }

  return (
    <div className="w-full grid grid-cols-2 h-[100vh]">
      <div className="flex items-center justify-center py-12">
        <Card className="mx-auto grid gap-6 w-[350px] p-6">
          <Tabs defaultValue="login">
            <TabsList className="w-fit mx-auto">
              <TabsTrigger value="login" className="font-medium">
                Login
              </TabsTrigger>
              <TabsTrigger value="signup" className="font-medium">
                Signup
              </TabsTrigger>
            </TabsList>

            <TabsContent value="signup">
              <Column className="gap-2 mt-4">
                <Input
                  label="Email"
                  autoComplete="email"
                  style={{ flex: 1 }}
                  {...form.register('email')}
                  // onKeyDown={getHotkeyHandler([['Enter', signUp]])}
                />
                <Input
                  label="Org. name"
                  style={{ flex: 1 }}
                  {...form.register('team_name')}
                />

                <Input
                  label="Invite code"
                  style={{ flex: 1 }}
                  {...form.register('invite_code')}
                />
                <p className="text-sm text-red-500 ml-1">
                  {form.formState.errors.invite_code?.message}
                </p>

                <Button
                  className="mt-2"
                  isLoading={loading}
                  onClick={signUp}
                  disabled={!form.watch('team_name')}
                >
                  Sign up
                  <LogIn className="h-4 w-4 ml-2" />
                </Button>

                <p className="text-sm text-muted-foreground text-center">
                  Sign up as the organization owner
                </p>

                <Alert className="mt-2">
                  <h2 className="text-sm font-medium">Trouble logging in?</h2>
                  <AlertDescription>
                    If you have any trouble logging in, please call/text{' '}
                    <a
                      href="https://www.linkedin.com/in/alfredodling"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Alfred
                    </a>{' '}
                    at +(46)73 68 68 686. 😊
                  </AlertDescription>
                </Alert>
              </Column>
            </TabsContent>

            <TabsContent value="login">
              <Column className="gap-4">
                <Input
                  label="Email"
                  autoComplete="email"
                  style={{ flex: 1 }}
                  {...form.register('email')}
                  placeholder="Your email"
                  // onKeyDown={getHotkeyHandler([['Enter', login]])}
                />

                <Button
                  isLoading={loading}
                  onClick={login}
                  disabled={!form.watch('email')}
                >
                  Enter
                  <LogIn className="h-4 w-4 ml-2" />
                </Button>
              </Column>
            </TabsContent>
          </Tabs>
        </Card>
      </div>

      <div className="bg-muted relative">
        <Image
          src="https://i.pinimg.com/736x/c0/2c/d0/c02cd07c52fb8660e096f8a1bac9f417.jpg"
          alt="Image"
          width="1920"
          height="1080"
          className="h-full w-full object-cover"
        />

        <Image
          src="https://assets.softr-files.com/applications/27733497-ddae-4314-ab9b-5bbf854005bc/assets/f318d98d-8580-42ae-b8c1-8f6596bdf586.png"
          alt="Image"
          width="300"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover shadow-lg p-5 rounded-full"
        />
      </div>
    </div>
  )
}
