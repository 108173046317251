import { useQueryClient } from '@tanstack/react-query'
import { Chrome, SendHorizonal } from 'lucide-react'
import { useState } from 'react'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useUsersApi } from 'src/api/hooks/apiUsers'
import { queryKeys } from 'src/api/hooks/queryKeys'
import { useInviteExtUsers } from 'src/api/server/useInviteExtUsers'
import { Column } from 'src/shared/components/Semantic/all'
import { Button, Card, Image } from 'src/shared/components/ui'
import { Textarea } from 'src/shared/components/ui/textarea'
import { useAuth } from 'src/shared/hooks/authProvider'

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../ui/carousel'
import onboarding from './onboarding.png'

export const OnboardingModal = () => {
  const [extensionEmails, setExtensionEmails] = useState('')
  const queryClient = useQueryClient()

  const inviteExtensionUsers = useInviteExtUsers()
  const { orgId } = useOrgIdApi()
  const { session } = useAuth()
  const { updateUser } = useUsersApi()

  const handleInviteExtension = async () =>
    await inviteExtensionUsers
      .mutateAsync({
        emails: extensionEmails,
        organization_id: orgId || '',
      })
      .then(() => {
        setExtensionEmails('')
        queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })
      })

  const Step1 = () => {
    return (
      <Column className="h-full">
        <h1 className="text-xl font-semibold">Get started</h1>

        <p className="text-sm font-normal text-muted-foreground mt-1">
          Install the chrome extension if you want to track your own tools.
        </p>

        <Button
          isLoading={inviteExtensionUsers.isLoading}
          className="mt-auto ml-auto"
          onClick={() =>
            window.open(
              'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
              '_blank'
            )
          }
        >
          Download extension
          <Chrome className="ml-2 h-4 w-4" />
        </Button>
      </Column>
    )
  }

  const Step2 = () => {
    return (
      <Column className="h-full">
        <h1 className="text-xl font-semibold">Invite employees</h1>

        <p className="text-sm font-normal text-muted-foreground mt-1">
          To collect and analyse tool usage from your team members, invite them
          to install the extension.
        </p>

        <Textarea
          className="mt-4"
          placeholder="extension@example.com, extension2@example.com, extension3@example.com"
          rows={5}
          value={extensionEmails}
          onChange={(e) => setExtensionEmails(e.target.value)}
        />

        <Button
          onClick={handleInviteExtension}
          isLoading={inviteExtensionUsers.isLoading}
          className="mt-auto ml-auto w-fit"
          disabled={extensionEmails.length === 0}
        >
          Send invites
          <SendHorizonal className="ml-2 h-4 w-4" />
        </Button>
      </Column>
    )
  }

  const Step3 = () => {
    return (
      <div className="grid grid-cols-5 gap-4">
        <Card className="p-3 col-span-3">
          <Image src={onboarding} height={600} width={600} />
        </Card>

        <Column className="col-span-2">
          <h1 className="text-xl font-semibold">
            Wait for your tools to appear
          </h1>

          <p className="text-sm font-normal text-muted-foreground mt-1">
            Wait about 10 minutes for the extension(s) to collect the initial
            data. They will appear in the Untracked section.
          </p>

          <Button
            onClick={() => {
              //@ts-ignore
              updateUser.mutate({
                id: session?.user?.id!,
                onboarded: true,
              })
            }}
            isLoading={updateUser.isLoading}
            className="mt-auto ml-auto w-fit"
          >
            Done
          </Button>
        </Column>
      </div>
    )
  }

  return (
    <Carousel>
      <CarouselContent>
        <CarouselItem>
          <Step1 />
        </CarouselItem>

        <CarouselItem>{Step2()}</CarouselItem>

        <CarouselItem>
          <Step3 />
        </CarouselItem>
      </CarouselContent>

      <CarouselPrevious variant="default" />
      <CarouselNext variant="default" />
    </Carousel>
  )
}
