import { useState } from 'react'

export const RequestFeaturePage = () => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div className="relative h-full w-full">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="h-8 w-8 animate-spin rounded-full border-4 border-neutral-200 border-t-neutral-800" />
        </div>
      )}

      <iframe
        src="https://app.getuservotes.com/public-board/10da15c8-eb22-42dd-b093-1a04a3624be5"
        style={{
          height: '100%',
          width: '100%',
          border: '0',
          borderRadius: '0.75rem',
        }}
        title="Instighto News"
        onLoad={() => setIsLoading(false)}
      />
    </div>
  )
}
