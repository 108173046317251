import React, { useEffect, useState } from 'react'
import { useOrgUsersApi } from 'src/api/hooks/apiOrgUsers'
import { useUsersApi } from 'src/api/hooks/apiUsers'
import { paths } from 'src/routes/paths'
import { OnboardingModal } from 'src/shared/components/OnboardingDialog'
import { Dialog, DialogContent } from 'src/shared/components/ui'
import { Avatar } from 'src/shared/components/ui/avatar'
import { cn } from 'src/shared/components/ui/utils'

import { OrgDialog } from './components/OrgDialog'
import { links } from './consts'
import { Sidebar, SidebarBody, SidebarLink } from './sidebar'

const avatar =
  'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.BitMB_DGEBBIrVLqrvTnBQHaFP%26pid%3DApi&f=1&ipt=cb39a1219b1bd344fdee9352549c337d5ab3980b13cf2037e9d1b71154a2a2da&ipo=images'

export function SidebarDemo({ children }: { children: React.ReactNode }) {
  const { orgUser } = useOrgUsersApi()
  const [onboarded, setOnboarded] = useState(true)
  const { user } = useUsersApi()

  useEffect(() => {
    setOnboarded(user?.data?.onboarded ?? true)
  }, [user?.data?.onboarded])

  return (
    <>
      <div
        className={cn(
          'rounded-md flex flex-col md:flex-row w-full flex-1 mx-auto border border-neutral-200 overflow-hidden',
          'h-screen'
        )}
      >
        <Sidebar>
          <SidebarBody className="justify-between gap-10">
            <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
              <OrgDialog />

              <div className="mt-2 flex flex-col gap-2">
                {links.map((link, idx) => (
                  <SidebarLink key={idx} link={link} />
                ))}
              </div>
            </div>

            <div>
              <SidebarLink
                link={{
                  label: orgUser?.data?.user?.first_name
                    ? `${orgUser?.data?.user?.first_name} ${orgUser?.data?.user?.last_name}`
                    : 'Click to add info',
                  href: paths.userSettings,
                  icon: <Avatar size="sm" src={avatar} />,
                }}
              />
            </div>
          </SidebarBody>
        </Sidebar>

        <div className="flex h-full flex-col flex-1 py-10 p-14 overflow-y-auto">
          {children}
        </div>
      </div>

      <Dialog open={!onboarded}>
        <DialogContent showClose={false} className="max-lg">
          <OnboardingModal />
        </DialogContent>
      </Dialog>
    </>
  )
}
