import { useVirtualizer } from '@tanstack/react-virtual'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Clock, User } from 'lucide-react'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useUserActivityApi } from 'src/api/hooks/apiUserActivity'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'

dayjs.extend(relativeTime)

export const Activity = () => {
  const navigate = useNavigate()
  const { orgId } = useOrgIdApi()
  const { userActivitiesAll } = useUserActivityApi({ org_id: orgId || '' })
  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: userActivitiesAll?.data?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 52, // Approximate height of each row
    overscan: 5,
  })

  return (
    <Column className="gap-4 w-full">
      <Card className="p-3">
        <div ref={parentRef} className="h-[70vh] overflow-auto">
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            <Table className="bg-gray-50 rounded-md">
              <TableBody>
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const userActivity =
                    userActivitiesAll?.data?.[virtualRow.index]
                  if (!userActivity) return null

                  return (
                    <TableRow
                      key={virtualRow.index}
                      className="flex flex-row justify-between absolute top-0 left-0 w-full h-[52px]"
                      style={{
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                    >
                      <TableCell>
                        <Row
                          className="items-center gap-2 cursor-pointer"
                          onClick={() =>
                            navigate('/stack/' + userActivity.tool_id)
                          }
                        >
                          <SaasIcon
                            toolName={userActivity.tool?.vendor?.name}
                            src={userActivity.tool?.vendor?.logo_url || ''}
                            size="sm"
                          />
                          {userActivity.tool?.vendor?.name}
                        </Row>
                      </TableCell>

                      <TableCell>
                        <Row className="items-center">
                          <User className="w-4 h-4 mr-2" />
                          {userActivity.org_user?.user?.email}
                        </Row>
                      </TableCell>

                      <TableCell>
                        <Row className="items-center font-medium">
                          <Clock className="w-4 h-4 mr-2" />
                          {dayjs(userActivity.last_visited).fromNow()}
                        </Row>
                      </TableCell>
                    </TableRow>
                  )
                })}

                {!userActivitiesAll?.data?.length && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Row className="items-center">
                        Wait 10 minutes for the extension to collect data.
                        <Clock className="w-4 h-4 ml-2" />
                      </Row>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </Card>
    </Column>
  )
}
