import { AlertCircle, ThumbsUp, X } from 'lucide-react'
import { useState } from 'react'
import { Column } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from 'src/shared/components/ui/alert'
import { Badge } from 'src/shared/components/ui/badge'
import { cn } from 'src/shared/components/ui/utils'

export function ValidateDomainsPage() {
  const [domains, setDomains] = useState([
    { url: 'pinn.one', isToggled: true },
    { url: 'google.com', isToggled: true },
    { url: 'github.com', isToggled: true },
    { url: 'youtube.com', isToggled: false },
    { url: 'twitter.com', isToggled: false },
  ])

  const toggleDomain = (url: string) => {
    setDomains((prev) =>
      prev.map((domain) =>
        domain.url === url
          ? { ...domain, isToggled: !domain.isToggled }
          : domain
      )
    )
  }

  return (
    <Column
      className="gap-4 w-full h-[100vh] items-center justify-center"
      style={
        {
          // backgroundImage: 'url(https://app.pinn.one/favicon.ico)',
          // backgroundRepeat: 'repeat',
          // backgroundColor: 'rgba(0, 0, 0, 1)',
          // backgroundBlendMode: 'lighten',
        }
      }
    >
      <Card className="glass-thick w-[500px]">
        <Column className="gap-2">
          <h2 className="text-lg font-medium">Validate your tools</h2>
          <p className="text-sm font-normal text-muted-foreground">
            We have auto-detected some tools for you, and will start to track
            the tools you approve.
          </p>

          <Alert className="my-3" variant="destructive">
            <AlertCircle className="w-4 h-4" />
            <AlertTitle>Important!</AlertTitle>
            <AlertDescription>
              We do not store any history of your browsing. This data only
              exists on your device.
            </AlertDescription>
          </Alert>

          <div className="flex flex-wrap gap-2 mt-4">
            {domains.map(({ url, isToggled }) => (
              <Badge
                key={url}
                variant={isToggled ? 'default' : 'outline'}
                className={cn(
                  'text-sm font-normal',
                  'cursor-pointer transition-all duration-50 hover:shadow-md rounded-full'
                )}
                onClick={() => toggleDomain(url)}
              >
                {url}
                {isToggled && <X className="ml-1 h-3 w-3" />}
              </Badge>
            ))}
          </div>

          <Button
            variant={'outline'}
            className="mt-6 w-fit ml-auto bg-blue-100 text-blue-900"
            onClick={() => {}}
          >
            Track these {domains.filter(({ isToggled }) => isToggled).length}{' '}
            tools
            <ThumbsUp className="ml-2 w-4 h-4" />
          </Button>
        </Column>
      </Card>
    </Column>
  )
}
