export const queryKeys = {
  org_user: 'org_user',
  vendorsSearch: 'vendorsSearch',
  tools: 'tools',
  subscriptions: 'subscriptions',
  org_users: 'org_users',
  vendors: 'vendors',
  vendor: 'vendor',
  organization: 'organization',
  organizations: 'organizations',
  org_id: 'org_id',
  user_activities: 'user_activities',
  user: 'user',
  overlapping_tools: 'overlapping_tools',
}
